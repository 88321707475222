<template>
  <div class="m-6 flex flex-col flex-1 text-base-content">

        <div v-if="isLoading" class="flex min-h-full min-w-full justify-center items-center rounded-md">
            <loader class="my-5" height="32px" width="32px" :loading="isLoading" />
                    Loading
        </div>
        <div v-if="descriptionData && !isLoading">
            <div class="flex gap-6 flex-col">
                <div class="flex">
                    <div class="w-full">
                        <span class="text-md font-medium">
                        Screening Package Name
                        </span>
                        <div v-if="editMode" class="flex mt-2">
                            <Input
                                v-model="descriptionData.package_name"
                                placeholder="Field Name"
                                bg="white"
                                class="w-4/12 packageNameEditableInput"
                                :editMode='editMode'
                                @removeEditablePackName='removeEditablePackName'
                                @focusInput='focusInput'
                            >
                            </Input>
                            <font-awesome-icon @click="hideEditMode"  v-on-click-outside="disableInputEditMode" icon="edit" class="mx-5 my-auto text-gray-300 editIcon"/>
                        </div>
                        <div v-else class="flex mt-2 packageNameInput">
                            <p class="pl-4 flex items-center gap-3 m-0 border border-solid border-box-stroke w-4/12 cursor-not-allowed py-2 rounded-md break-all text-sm packageNameInput">
                                {{ descriptionData.package_name ?  descriptionData.package_name : 'No package name' }}
                            </p>
                            <font-awesome-icon @click="handleClick"  v-on-click-outside="disableInputEditMode" class="hover:text-primary cursor-pointer mx-5 my-auto editIcon" icon="edit" /> 
                        </div>
                    </div>
                </div>

                <div class="flex flex-col gap-3 w-12/12">
                    <div>
                        <span class="text-base-content-300 text-md font-medium"> Description </span> 
                        <font-awesome-icon v-allow="'package.edit'" @click="handleClickTextarea" v-on-click-outside="disableEditMode" class="mx-5 my-auto editIcon"
                            icon="edit" :class="{ 'text-gray-300' : editTextArea }" />
                    </div>
                    <textarea
                    v-model="descriptionData.package_description"
                    class="textarea textarea-bordered focus:textarea-primary text-base-content-300 border-box-stroke"
                    placeholder="Description"
                    id=""
                    rows="3"
                    :class="{'viewDescText' : !editTextArea}"
                    :disabled="!editTextArea"
                    @blur='removeEditableMode'
                    @focus='focusTextarea'
                    ></textarea>
                </div>
                </div>
            <!-- <div v-else>loading...</div> -->

            <div class="mt-4 flex justify-end max-w-full gap-4">
                <Button type="primary" text="Save" @click="updatePackage" />
            </div>

        </div>
  </div>
</template>
<script>
import Input from "@/components/input";
import axios from "@/axios";
import loader from "@/components/loader";
import { mapActions, mapGetters, mapMutations } from "vuex";
import Button from "@/components/button"

export default {
    name: "description",
    components: {
        Input,
        loader,
        Button,
    },
    props: [],

    data: () => ({
        editMode: false,
        descriptionData: null,
        isLoading: false,
        isTextAreaClicked: false,
        isInputClicked: false,
        // package:  {
        //   id: "",
        //   // package_name: "",
        //   // package_name_internal: "",
        //   package_description: "",
        //   // turn_around_time: null,
        //   // client_email_template: null,
        //   // candidate_email_template: null,
        //   // max_candidate_chase_email: null,
        //   // min_candidate_chase_email: null,
        //   // tenant_id: "3ea5e89b-1d80-4b7d-a4db-852d81126c8d",
        //   // is_custom: false,
        //   // mark_as_default: null
        // }
    }),
    async mounted() {
        await this.fetchScreeningData();
    },

    computed: {
        ...mapGetters(["getPackage"]),
    },
    methods: {
        ...mapActions(["fetchPackageData"]),
        ...mapMutations(["SET_PACKAGE"]),
        async fetchScreeningData() {
        this.isLoading = true;
        if(!this.getPackage){
            await this.fetchPackageData(this.$route.params.packageName)
            this.descriptionData = this.getPackage
        }
        this.descriptionData = this.getPackage
        this.isLoading = false;
        },

        async updatePackage() {
            const package_id = this.$route.params.packageName;

            const url = `/screening-package/${package_id}`;
            const payload = {
                package_name: this.descriptionData.package_name,
                package_description: this.descriptionData.package_description,
            };
            try {
                await axios.put(url, payload);
                this.SET_PACKAGE = {...this.getPackage, package_description:this.descriptionData.package_description}
                this.$toast.success("Package description updated.");
            } catch (error) {
                this.$toast.warning(error.response.data.detail ||  "Could not update package.");
            }
        },
        handleClick() {
            this.editMode = true;
        },
        hideEditMode(){
            this.editMode = false;
        },
        handleClickTextarea() {
            this.editTextArea = !this.editTextArea
        },
        disableEditMode(){
            if(!this.isTextAreaClicked)
                this.editTextArea = false;
            this.isTextAreaClicked = false;
        },
        disableInputEditMode(){
            if(!this.isInputClicked)
                this.editMode = false;
            this.isInputClicked = false
        },
        removeEditableMode() {
            if(this.editTextArea)  
                this.editTextArea = false;
        },
        removeEditablePackName(val) {
            this.editMode = val;
        },
        focusTextarea() {
            this.isTextAreaClicked = true;
        },
        focusInput(val) {
            this.isInputClicked = val;
        },
    },
};
</script>